@import "../../../styles/variables.scss";
@import "../../../styles/modal";

.wheel-congrats-modal {
  @extend .modal;

  .ant-modal-content {
    .ant-modal-body {
      background: url("../../../assets/images/confetti.png") no-repeat center top;

      .content {
        color: $primary-color;

        .title {
          margin-top: 35px;
          margin-bottom: 24px;
          background-color: $white;
        }

        .gift {
          width: 50%;
          margin-bottom: 12px;
        }

        .message {
          font-weight: 500;
          font-size: 18px;
          line-height: 42px;
          text-align: center;
        }

        .text {
          font-size: 14px;
          line-height: 17px;
          color: $gray;
          margin-bottom: 24px;
        }
      }
    }
  }
}
