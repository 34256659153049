@import "../../styles/variables.scss";

#home-page {
  background: url(../../assets/images/home-bg.png) no-repeat center;
  background-size: cover;

  .page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    padding: 20px 24px;
    color: #ffffff;
    max-width: 375px;

    .fener-logo {
      width: 48px;
      height: 48px;
    }

    .home-page-heading {
      color: #ffffff;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
    }

    .home-page-context {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    .home-page-wheel {
      display: flex;
      justify-content: center;
      .wheel {
        width: 95%;
        max-width: 300px;
      }
    }

    .mpi-text {
      font-size: 10px;
      font-weight: 500;
      margin-top: 16px;
    }
  }
}
