@import "../../styles/variables";

#loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background: url(../../assets/images/bg-loading.png) no-repeat center;
  background-size: cover;
}
