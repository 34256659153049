@import "../../../styles/modal";

.how-to-use-modal {
  @extend .modal;

  .content {
    .text {
      text-align: left;
      max-height: 266px;
      overflow-y: auto;
    }
  }
}
