@import "../../styles/variables.scss";

#wheel-page {
  .page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    padding-bottom: 122px;

    .fener-logo {
      width: 48px;
      height: 48px;
    }

    .wheel-loading {
      .ant-spin-dot {
        font-size: 52px;

        .ant-spin-dot-item {
          background-color: $white;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
