@import "variables";

.modal {
  &.ant-modal {
    .ant-modal-content {
      border-radius: 16px;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
          margin: 30px 0 10px;
          max-width: 160px;
          max-height: 160px;
        }

        .title {
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          color: $primary-color;
          text-align: center;
          margin-top: 16px;
        }

        .text {
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          color: $gray;
        }

        #button-secondary {
          margin-top: 10px;
        }
      }
    }
  }
}
