@import "../../../styles/variables.scss";
@import "../../../styles/modal";
@import "../../../styles/form";
.otp-modal {
    @extend .modal;
    .ant-modal-content {
        .sms-icon {
            width: 65px;
        }
        #otp-form {
            @extend .form;
            width: 100%;
            .ant-form-item {
                .ant-form-item-control-input {
                    .ant-form-item-control-input-content {
                        .ant-input {
                            height: 61px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
