@import "../../../styles/variables.scss";

#wheel4p {
  overflow: hidden;
  position: relative;

  .button-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65px;
    height: 65px;
    border-radius: 100px;
    background: #002d72;
    box-shadow: inset 0 4.3923px 2.9282px #2c69c7;
    color: white;
    padding: 0;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: 0.6655px;
    white-space: pre-wrap;

    .ant-spin {
      padding-top: 4px;

      .ant-spin-dot-item {
        background-color: $white;
      }
    }

    .button-gif {
      width: 125%;
      height: 125%;
      transform: translate(-10px, -6px);
    }
  }

  .overlay {
    $padding: 10px;
    z-index: 5;
    position: absolute;
    top: $padding;
    bottom: $padding;
    left: $padding;
    right: $padding;
    background-color: rgba(0, 0, 0, 0.45);
    backdrop-filter: blur(4px);
    border-radius: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .info {
      width: 180px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.6655px;
      margin-bottom: 25px;
    }

    .button-wrapper {
      display: flex;
      flex-direction: column;
      width: 165px;

      .button {
        font-weight: 500;
        height: 40px;
        border-radius: 16px;

        &.button-blue {
          background-color: #032f73;
          color: white;
          border: 2px solid #032f73;
          margin-bottom: 8px;
        }

        &.button-yellow {
          background-color: #ffed03;
          color: #032f73;
          border: 2px solid #032f73;
        }
      }
    }
  }

  @media (min-width: 400px) {
    .button-spin {
      padding: 10px;
      width: 100px;
      height: 100px;
    }
  }
}
