@import "../../../styles/form";
@import "../../../styles/table";

#app-layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: url(../../../assets/images/bg.png) no-repeat center;
  background-size: cover;

  .page {
    display: flex;
    flex-grow: 1;
  }
}
