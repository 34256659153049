@import "../../../../styles/variables.scss";
@import "../../../../styles/form";

#user-form {
  @extend .form;
  width: 100%;

  .contract-link {
    color: black;
    font-weight: 700;
  }
  .link {
    color: $gray;
    font-size: 14px;
    text-align: center;
  }
}
