@import "colors";
@import "texts";

$primary-color: #002d72;
$secondary-color: #ffed00;
$secondary-dark-color: #ffed00;
$form-background: #ececec;
$page-background: #f0f2f5;
$white: #ffffff;
$black: #000000;
$gray: #474747;
$light-gray: #d8d8d8;
$light-blue: #a1b5d3;
$input-bg: #e7eefa;
$gold: #b3926f;
$page-width-768: 608px;
$page-width-1024: 608px;
$page-width-1280: 683px;
